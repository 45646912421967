@font-face {
  font-family: "TerminatorGenisys";
  src: url("./fonts/TerminatorGenisys.ttf");
}
* {
  font-family: "TerminatorGenisys";
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.App {
  height: 100vh;
  overflow: hidden;
}
.autorization {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 49%,
    rgba(161, 120, 243, 1) 100%
  );
}
.auth__body {
  padding: 1.5vw;
  border-radius: 1vw;
  display: flex;
  background: #fff;
  flex-direction: column;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.auth__body p {
  font-size: 1vw;
  margin-left: 1vw;
  margin-bottom: 0.2vw;
}
.auth__body input {
  padding: 0.5vw;
  font-size: 1vw;
  border-radius: 1vw;
  border: 1px solid black;
  margin-bottom: 1vw;
}
.auth__body h3 {
  text-align: center;
  font-size: 1.5vw;
  margin-bottom: 1vw;
}
.auth__body div {
  font-family: "RoadRadio";
  color: #fff;
  background: linear-gradient(94.5deg, #9873e5 26.56%, #a178f3 100.53%);
  box-shadow: 1px 6px 16px rgba(181, 125, 216, 0.25);
  padding: 0.9vw 0.5vw;
  border-radius: 0.5vw;
  text-align: center;
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
