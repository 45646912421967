.database_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.database_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
.database_title {
  font-size: 3.03vw;
  line-height: 2.9vw;
  margin-left: 2.8vw;
  width: fit-content;
}
.mainRightLine {
  margin-bottom: 0.5vw;
  margin-left: 2vw;
}
.database_content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.phone_page {
  display: flex;
  padding: 3.3vw 0 2.6vw 0;
}
.phone_page_box_white_background {
  background: #ffffff;
  border-radius: 2.1vw;
}
.phone_page_box {
  border-radius: 2vw;
  width: 21vw;
  overflow-y: scroll;
  height: 34.1vw;
  border: 0.4vw solid #ab84fd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.1vw 1vw;
  background: url(../../img/databasebackground.png);
  background-size: cover;
}
.phone_page_box::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.phone_page_button_onMain {
  align-self: flex-start;
  display: flex;
  margin-bottom: 0.5vw;
}
.text_transform {
  font-family: "RoadRadio";
  text-transform: uppercase;
}
.right_settings_image_load_button {
  text-align: center;
}
.arrowButtonBack {
  width: 1.85vw;
}
.phone_page_button_onMain_title {
  font-size: 0.75vw;
  margin-left: 0.2vw;
}
.phone_page_title {
  font-size: 1.92vw;
  margin-bottom: 0.9vw;
}
.phone_page_content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.phone_page_content_part {
  width: 100%;
  height: 6.2vw;
  border-radius: 0.5vw;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 0.7vw;
  align-items: center;
  margin-bottom: 0.7vw;
  box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
  position: relative;
  text-decoration: none;
  color: #1b1b1b;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.5s;
}
.phone_page_content_part:hover {
  transform: translateY(-5px);
}
.modal__delete {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.modal__delete__body {
  padding: 2vw;
  border-radius: 1vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25vw;
}
.modal__delete__body input {
  outline: none;
  padding: 1vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  margin: 1vw 0;
  width: 100%;
}
.modal__delete__body p {
  font-weight: 600;
  text-align: center;
  font-size: 1.5vw;
  font-family: "RoadRadio";
}
.modal__delete__body div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modal__delete__body div button {
  width: 10vw;
  text-align: center;
  padding: 0.5vw 1vw;
  font-size: 1vw;
  background: #ab84fd;
  border: none;
  box-sizing: border-box;
  border-radius: 0.5vw;
  font-family: "RoadRadio";
  cursor: pointer;
  color: #fff;
  outline: none;
}
.phone_page_content_part:checked {
  color: #1b1b1b;
}
.phone_page_content_part:active {
  color: #1b1b1b;
}
.phone_page_content_part:visited {
  color: #1b1b1b;
}
.phone_page_content_part:focus {
  color: #1b1b1b;
}
.phone_page_content_part:focus-visible {
  color: #1b1b1b;
}
.phone_page_content_part_hover {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0.5vw;
  cursor: pointer;
  transition: 0.3s;
}
.phone_page_content_part_hover:hover {
  opacity: 1;
  background: linear-gradient(
      0deg,
      rgba(89, 46, 180, 0.34),
      rgba(89, 46, 180, 0.34)
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
}
.phone_page_content_part_hover_content {
  font-size: 1vw;
  font-weight: 700;
  background: #ffffff;
  border-radius: 0.8vw;
  padding: 1vw 1.5vw;
  cursor: pointer;
}
.phone_page_content_part:last-of-type {
  margin-bottom: 0;
}
.chooseSectionImg {
  width: 5vw;
  margin-top: 0.5vw;
  margin-right: 0.5vw;
}
.phone_page_content_part_number {
  display: flex;
  align-items: center;
}
.stepsNumber {
  font-family: "RoadRadio";
  font-size: 4.3vw;
  color: rgba(83, 0, 253, 0.1);
}
.phone_page_content_part_title {
  font-weight: 900;
  font-size: 1.05vw;
  margin-left: 0.7vw;
}
.phone_page_outside_buttons {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  margin-top: 2vw;
  height: 28.5vw;
  background: #e4ddf9;
  padding: 0 1.45vw;
  border-radius: 0 1.5vw 1.5vw 0;
}
.outside_buttons_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}
.outside_buttons_box:hover {
  transform: translateY(-5px);
}
.outside_buttons_img {
  background: #ffffff;
  width: 3.15vw;
  height: 3.15vw;
  border-radius: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25vw;
  cursor: pointer;
}
.outsideButtonsImg {
  width: 1.825vw;
}
.outside_buttons_title {
  font-size: 0.65vw;
  font-weight: 900;
}

.mic {
  position: absolute;
  top: 27vw;
  left: 58vw;
  transform: translate(-50%, -50%) scale(0.7);
  color: #fff;
}
.mic::before,
.mic::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: 2;
}
.mic::before {
  width: 15vw;
  height: 15vw;
  background-color: #1a084e;
}
.mic::after {
  width: 300px;
  height: 300px;

  animation: circle-size 1.6s linear infinite alternate;
}
.mic-icon {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  display: block;
  width: 16px;
  height: 12px;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  border: 2px solid;
  border-top: 0;
  margin-top: 20px;
  z-index: 3;
}
.mic-icon::before,
.mic-icon::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}
.mic-icon::before {
  width: 2px;
  height: 5px;
  top: calc(100% + 1px);
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
}
.mic-icon::after {
  border: 2px solid;
  width: 8px;
  height: 18px;
  left: 50%;
  top: -10px;
  border-radius: 4px;
  transform: translate(-50%, 0);
}
.mic img {
  position: relative;
  z-index: 5;
  width: 28vw;
  animation: imgSize 4s ease-out infinite;
}
.mic-shadow {
  width: 20vw;
  height: 20vw;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  z-index: 1;
  box-shadow: 10px -55px 30px 15px #ab84fd, 24px -10px 47px 10px #aab3d2,
    -21px -25px 97px 10px #f7e9fc, 51px 5px 17px 10px rgba(149, 113, 226, 0.33),
    3px 2px 77px 10px #f30bf5;
  animation: shadow-rotate 3s linear infinite;
  transform-origin: center;
  opacity: 0.5;
}
@keyframes circle-size {
  from {
    width: 200px;
    height: 200px;
  }
  to {
    width: 300px;
    height: 300px;
  }
}
@keyframes imgSize {
  0% {
    width: 28vw;
  }
  50% {
    width: 27.5vw;
  }
  100% {
    width: 28vw;
  }
}
@keyframes shadow-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
  }
}
