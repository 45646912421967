
.add_section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add_section_title{
    font-size: 1.5vw;
    font-weight: 900;
    margin-bottom: 1.5vw;
}
.add_section_settings{
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.7vw;
    border-radius: 0.5vw;
}
.add_section_img_placeholder{
    background: #EEE6FF;
    width: 5.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.75vw;
    border-radius: 0.5vw;
    margin-right: 1vw;
}
.addSectionImg{
    width: 3.39vw;
}
.add_sectionView_title_placeholder{
    width: 8vw;
    font-weight: 500;
    font-size: 0.8vw;
    margin-bottom: 0.2vw;
}
.add_section_title_button_placeholder{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.add_section_button_placeholder{
    border: none;
    background: linear-gradient(93.33deg, #3ACD06 0.41%, #58E700 100.4%);
    color: #ffffff;
    border-radius: 0.3vw;
    padding: 0.5vw 2.6vw;
    font-size: 0.8vw;
    margin: 0;
    width: 100%;
    cursor: pointer;
}