.choosetemplatecontainer {
  background-image: url("../../img/fon.png");
  margin-left: 21vw;
  background-size: cover;
  padding: 3vw 0px 3vw 2.5vw;
  height: 100vh;
}
.title_bar {
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
}
.title_bar h1 {
  font-weight: 400;
  font-size: 3.2vw;
  margin-left: 1.9vw;
}
.title_bar img {
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
  border-radius: 0.5vw;
}
.choose__template_content {
  display: flex;
  align-items: center;
}
.grag__drop_ems {
  display: flex;
}
.input_img > div {
  display: flex;
  align-items: center;
}
.input_title input {
  padding: 1vw;
  border-radius: 0.5vw;
  outline: none;
  width: 100%;
  border: 2px solid #ab84fd;
}
.input_img p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 100.1%;
  /* identical to box height, or 20px */

  margin-left: 10.8vw;
  margin-right: 1vw;

  color: rgba(27, 27, 27, 0.48);
}
.input_img .green__button {
  width: 11.5vw;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.input_img {
  background: #f6f5fb;
  box-shadow: 0px 4px 43px rgba(95, 70, 146, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5vw 1.5vw;
}
.input_img p {
  margin-left: 0 !important;
}
.choose__template__em {
  margin-right: 2vw;
  width: 13vw;
  transition: 0.5s;
  cursor: pointer;
}
.choose__template__em img {
  width: 100%;
}
.choose__template__em:hover {
  transform: translateY(-5px);
}
.choose__template_content {
  margin-bottom: 3.5vw;
}
.choose__template_content .green__button {
  margin-left: 1.8vw;
}
.green__button {
  padding: 1vw 4.1vw;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 0.3vw;
  color: white;
  text-transform: uppercase;
  font-family: "RoadRadio";
  font-weight: 400;
  font-size: 1vw;
  cursor: pointer;
}
.input_title {
  width: 19vw;
  margin-right: 1.6vw;
  padding: 0 1vw 0.8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6f5fb;
  box-shadow: 0px 4px 43px rgba(95, 70, 146, 0.3);
  border-radius: 1vw;
}
.input_title p,
.input_img > p {
  font-weight: 400;
  font-size: 1.5vw;
  color: #fff;
  text-align: center;
  padding: 0.7vw 1vw 1.3vw;
  background: #ab84fd;
  border-radius: 0 0 1vw 1vw;
  margin-bottom: 1.1vw;
  width: min-content;
}
.input_title textarea {
  border: 2px solid #ab84fd;
  border-radius: 7px;
  height: 72px;
  width: 100%;
}
