.dbDocuments_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.dbDocuments_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
.chooseSectionImgDocs {
  width: 6.05vw;
}
.phone_page_content_part_title_docs {
  font-size: 0.85vw;
  width: 5vw;
}
.phone_page_content_part_number_docs {
  display: flex;
  align-items: flex-start;
  margin-left: 0.7vw;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.8vw 0 1vw 0;
}
