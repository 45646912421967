.right_settings_save_changes_button {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9vw;
  border-radius: 0.3vw;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border: none;
  color: #ffffff;
  font-size: 1.2vw;
  letter-spacing: 0.025vw;
  transition: 0.5s;
}
.right_settings_save_changes_button:hover {
  transform: scale(1.05);
}
