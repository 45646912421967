.dbPromotion_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.dbPromotion_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
.lower_padding {
  margin: 0 -0.5vw;
}
