
.add_section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add_section_title{
    font-size: 1.5vw;
    font-weight: 900;
    margin-bottom: 1.5vw;
}
.add_section_settings{
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.7vw;
    border-radius: 0.5vw;
}
.add_section_img_placeholder{
    background: #EEE6FF;
    width: 5.4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.75vw;
    border-radius: 0.5vw;
    margin-right: 1vw;
}
.addSectionImg{
    width: 3.39vw;
}
.add_section_title_placeholder{
    width: 8vw;
    font-weight: 900;
    font-size: 1.05vw;
    margin-left: 0.7vw;
}