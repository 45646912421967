.dbWant_to_join_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.dbWant_to_join_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
#table_add > div {
  display: flex !important;
  flex-direction: row !important;
}
#table_add input {
  padding: 0.5vw !important;
  border-radius: 0 !important;
  margin: 0 !important;
}
#table_add {
  flex-direction: column !important;
}
.add_line {
  font-family: "RoadRadio";
  background: linear-gradient(94.5deg, #9873e5 26.56%, #a178f3 100.53%);
  color: #ffffff;
  font-size: 0.8vw;
  padding: 0.5vw 1vw;
  margin: 0.5vw auto;
  border-radius: 0.5vw;
  text-align: center;
  width: 12vw !important;
  justify-content: center !important;
  cursor: pointer;
}
.first_type {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 0.7vw;
  background: linear-gradient(94.5deg, #9873e5 26.56%, #a178f3 100.53%);
  box-shadow: 1px 6px 16px rgba(181, 125, 216, 0.25);
  padding: 0.9vw 0.5vw;
  border-radius: 0.5vw;
  margin: 0.5vw 0;
  text-align: center;

  color: #ffffff;
}
.link_date {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.5vw;
  overflow: hidden;
  margin: 0.5vw 0;
}
.link_date p {
  background: #ab84fd;
  color: #fff;
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;
  font-weight: 900;
  font-size: 0.7vw;
  text-align: center;
  padding: 0.5vw;
}
.link_date div {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vw 1vw;
}
.link_date div span {
  font-family: "RoadRadio";
  font-style: normal;
  font-size: 0.7vw;
}
.link_date a {
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;

  font-size: 0.7vw;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
}
.second_type {
  font-size: 0.7vw;
  font-family: "RoadRadio";
  text-align: center;
  margin: 0.5vw 0;
}
.third_type {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 0.85vw;
  text-align: center;
  /* or 17px */

  text-align: center;
  margin: 1vw 0;
  margin-bottom: 0.5vw;
  color: #1b1b1b;
}
.fourth_type h4 {
  background: linear-gradient(
    94.5deg,
    rgba(86, 26, 211, 0.6) 26.56%,
    rgba(83, 0, 253, 0.48) 100.53%
  );
  padding: 0.5vw;
  text-align: center;
  font-family: "RoadRadio";
  color: #fff;
  border-radius: 0.5vw 0.5vw 0 0;
}
.fourth_type p {
  background-color: #fff;
  border-radius: 0 0 0.5vw 0.5vw;
  padding: 0.5vw 1vw;
  text-align: center;
  font-size: 0.7vw;
  font-family: "RoadRadio";
}
.fourth_type {
  box-shadow: 1px 6px 16px rgba(181, 125, 216, 0.25);
  font-family: "RoadRadio";
  border-radius: 0 0 0.5vw 0.5vw;
  margin: 0.5vw 0;
}
.fiveth_type {
  width: 4.5vw;
  height: 4.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5vw auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #561ad3;
}
.fiveth_type img {
  width: 100%;
}
.sixth_type {
  width: 100%;
  margin: 0.5vw 0;
}
.sixth_type iframe {
  width: 100%;
  border-radius: 0.5vw;
  height: 10vw;
}
.seven_type {
  margin: 0.5vw 0;
}
.seven_type img {
  width: 100%;
  border-radius: 0.5vw;
}
.eit_type {
  display: flex;
  align-items: flex-start;
  margin: 0.5vw 0;
  align-self: flex-start;
}
.eit_type img {
  width: 1.5vw;
  height: 1.5vw;
  background: #561ad3;
  border-radius: 0.2vw;
  margin-right: 0.5vw;
}
.eit_type p {
  font-size: 0.7vw;
  font-family: "RoadRadio";
}
.nine_type {
  border-radius: 0.5vw;
  overflow: hidden;
  margin: 0.5vw 0;
}
.nine_type div {
  display: flex;
  align-items: center;
  font-size: 0.7vw;
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  background: #e0d1ff;
  width: 100%;
  /* identical to box height, or 15px */
  margin: 0 auto;
  color: #654d96;
}
.nine_type div p {
  font-family: "RoadRadio";
  border-right: 1px solid #ac9cd1;

  padding: 0.3vw 1vw;
  width: 50%;
}
.nine_type div span {
  font-family: "RoadRadio";
  width: 50%;
  padding: 0.3vw 1vw;
}
.add__element {
  background: #fefdff;
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
  border-radius: 1vw;
  padding: 1vw 2vw;
  width: 30vw;
}
.add__element div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 0;
  border-bottom: 2px solid rgba(35, 1, 106, 0.1);
}
.add__element div img {
  width: 12vw;
  transition: 0.5s;
  cursor: pointer;
}
.modal__delete__body div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add__element div img:hover {
  transform: translateY(-5px);
}
.new {
  width: 4vw !important;
}
.next_button {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 0.3vw;
  text-transform: uppercase;
  padding: 0.5vw 2vw !important;
  color: #fff;
  font-family: "RoadRadio";
  cursor: pointer;
  font-size: 1vw;
}
