.add_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_section_title {
  font-size: 1.5vw;
  font-weight: 900;
  margin-bottom: 1.5vw;
}
.add_coin_settings {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 1.66%;
  margin-bottom: 0.6vw;
  cursor: pointer;
  transition: 0.5s;
}
.add_coin_settings:hover {
  transform: translateY(-5px);
}
.add_coin_settings_title {
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  border-radius: 0.5vw 0.5vw 0 0;
  color: #ffffff;
  width: 100%;
  text-align: center;
  font-size: 0.6vw;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3vw;
}
.add_coin_settings_img_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 0.3vw 0;
}
.add_coin_settings_img {
  width: 2.1vw;
  height: 2.1vw;
}
.add_coin_settings_button {
  text-decoration: none;
  text-align: center;
  background: rgba(59, 225, 0, 1);
  border: none;
  border-radius: 0 0 0.5vw 0.5vw;
  color: #ffffff;
  font-size: 0.7vw;
  padding: 0.25vw 0;
}
