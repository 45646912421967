.dbWhere_to_start_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.dbWhere_to_start_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
.text_transform2 {
  font-family: "RoadRadio";
  font-weight: 900;
}
.phone_page_undertitle {
  width: 100%;
  font-size: 0.85vw;
  text-align: center;
  margin-bottom: 0.3vw;
  margin-bottom: 1vw;
}
.pres_links {
  padding: 0.5vw;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
  margin-bottom: 0.5vw;
  width: 100%;
  border-radius: 0.3vw;
  align-items: center;
}
.pres_links p {
  font-family: "RoadRadio";
  font-weight: 700;
  font-size: 0.95vw;
}
.img_title {
  height: 1.5vw;
  width: 1.5vw;
  padding: 0.4vw;
  box-sizing: content-box;

  border-radius: 0.3vw;
  background: linear-gradient(
    94.5deg,
    #561ad3 26.56%,
    rgba(83, 0, 253, 0.8) 100.53%
  );
  margin-right: 0.7vw;
}
.arrow {
  width: 3.5vw;
  height: 2.5vw;
  background: #3be100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3vw;
  cursor: pointer;
  justify-self: flex-end;
}
.arrow img {
  width: 40%;
}
.phone_page_content_part_title_start {
  font-size: 0.8vw;
}
.phone_page_content_part_number_start {
  display: flex;
  align-items: flex-start;
  margin-left: 0.7vw;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 10vw;
  padding: 0.8vw 1vw 1vw 0;
}
