.dbResources_container_main {
  width: 100vw;
  height: 100vh;
  padding-left: 21vw;
  /* background-image: url("../../img/fon2.png");
    background-size: cover; */
}
.dbResources_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4.5vw;
  background-image: url("../../img/fon.png");
  background-size: cover;
}
.row_coins {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0.7vw;
  margin-bottom: 0.7vw;
}
.coins_title_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  position: relative;
  border-radius: 0.3vw;
  box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
  padding: 1.8vw 0.9vw 0.3vw 0.9vw;
  margin-bottom: 0.7vw;
}
.coinsTitleImg1 {
  width: 2.81vw;
  position: absolute;
  top: -1.25vw;
}
.coins_title_text {
  width: 10vw;
  font-size: 0.6vw;
  font-weight: 700;
  text-align: center;
}
.text_transform3 {
  font-family: "RoadRadio";
  font-weight: 900;
  font-size: 0.81vw;
}
