.wp_main {
  background-image: url("../../img/fon.png");
  background-size: cover;
  margin-left: 21vw;
  width: calc(100% - 21vw);
  padding-bottom: 3.6vw;
  height: 100vh;
}
.wp_header {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.wp_header_sections_link:hover {
  color: #fff;
  background: #a47ef7;
}
.wp_header_structure {
  transition: 0.5s;
}
.wp_header_structure:hover {
  transform: translateY(-5px);
}
.wp_header_sections {
  width: 60.4vw;
  background: #fbfbfd;
  border-right: 1px solid #e8eaf1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.3vw;
  padding-top: 2.9vw;
  padding-left: 1.6vw;
  padding-right: 2.1vw;
}
.wp_header_sections > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 110%;
  color: rgba(149, 113, 226, 0.33);
}
.wp_header_sections_link {
  transition: 0.5s;
  background: rgba(149, 113, 226, 0.33);
  box-shadow: 1px 6px 16px rgba(181, 125, 216, 0.25);
  border-radius: 1vw;
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 110%;
  text-align: center;
  color: #1b1b1b;
  padding: 1vw 1vw 1vw 1vw;
  text-decoration: none;
}
.wp_header_structure {
  padding: 1.5vw 0;
  background: #f3eeff;
  border-right: 1px solid #e8eaf1;
  width: 19vw;
  justify-content: center;
  display: flex;
  align-items: center;
}
video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.wp_header_structure_link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background: #a47ef7;
  border-right: 1px solid #e8eaf1;
  border-radius: 1vw;
  padding: 1.5vw 1vw 1.5vw 1vw;
  text-decoration: none;
}
.wp_header_structure_link > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
}
.wp_header_structure_link > img {
  width: 1.7vw;
  height: 1.7vw;
  margin-left: 0.5vw;
}
.wp_main_in_welcome {
  margin-left: 3.5vw;
  width: 66.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3.2vw;
  align-items: center;
  margin-bottom: 3vw;
}
.wp_main_in_welcome > img {
  width: 11vw;
  height: 0.45vw;
}
.wp_main_in_welcome > p {
  font-style: normal;
  font-weight: 400;
  font-size: 3.5vw;
  line-height: 78.1%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1b1b;
}
.wp_main_in_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10vw;
  width: 54vw;
  margin-bottom: 4.3vw;
}
.wp_main_in_center_left1 img {
  width: 14vw !important;
  margin-bottom: 0 !important;
  transition: 0.5s;
}
.wp_main_in_center_left1 img:hover {
  transform: translateY(-10px);
}
.wp_main_in_center_left {
  background: #ffffff;
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
  border-radius: 1vw;
  padding: 2.1vw 0 2.1vw 0;
  transition: 0.5s;
  height: 20.5vw;
  margin-top: 0.5vw;
  display: flex;
  align-items: center;
}
.wp_main_in_center_left_link,
.wp_main_in_center_right_link {
  text-decoration: none;
  transition: 0.5s;
}
.wp_main_in_center_left:hover {
  transform: translateY(-5px);
}
.wp_main_in_center_right_link:hover {
  transform: translateY(-5px);
}
.wp_main_in_center_left_link > img {
  width: 12vw;
  margin-bottom: 1.6vw;
}
.wp_main_in_center_left_link > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 100.1%;
  text-align: center;
  color: #1b1b1b;
  text-decoration: none;
}
.wp_main_in_center_sphere {
  width: 18.54vw;
  height: 18.54vw;
}
.wp_main_in_center_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wp_main_in_center_right_link {
  background: #ffffff;
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
  border-radius: 20px;
  padding: 1vw 2.1vw 1.3vw 2.2vw;
}
.wp_docs {
  width: 7.91vw;
}
.wp_profile {
  width: 6.71vw;
}
.wp_main_in_center_right_link > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 100.1%;
  text-align: center;
  color: #1b1b1b;
}
.wp_main_in_bottom {
  margin-left: 10vw;
  width: 54vw;
  box-shadow: 1px 6px 16px rgba(181, 125, 216, 0.25);
  display: flex;
  flex-direction: row;
  border-radius: 1vw;
}
.wp_main_in_bottom_left {
  width: 21vw;
  background: #9571e2;
  padding: 1.9vw 1.4vw 1.6vw 2.1vw;
  border-radius: 1vw 0px 0px 1vw;
}
.wp_main_in_bottom_left > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 1.4vw;
  line-height: 104.6%;
  text-align: center;
  color: #ffffff;
}
.wp_main_in_bottom_right {
  width: 33vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border-radius: 0px 1vw 1vw 0px;
  padding: 1vw 0px 1vw 0px;
}
.wp_main_in_bottom_right_id {
  width: 11.5vw;
  background: #eae5f3;
  border-radius: 0.4vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6vw 1vw 0.6vw 1vw;
  margin-left: 2vw;
}
.wp_main_in_bottom_right_id > p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6vw;
  line-height: 104.6%;
  text-align: center;
  color: #1b1b1b;
}
.wp_copy_btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.wp_copy_btn > img {
  width: 1.6vw;
  height: 1.6vw;
}
.wp_bottom_text {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 104.6%;
  color: #1b1b1b;
  margin-left: 1.5vw;
}
