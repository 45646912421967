.right_settings_position {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.right_settings_position_title {
  font-size: 1.6vw;
  font-weight: 900;
  margin-right: 0.7vw;
}
.right_settings_position_position_number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6vw;
  height: 3.6vw;
  background: #ffffff;
  border-radius: 1.1vw;
  padding-left: 0.5vw;
  font-size: 2.9vw;
  font-weight: 700;
  border: 0.15vw solid #ab84fd;
}
