
.database_right_settings_coins{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
    height: 95%;
    justify-content: space-between;
    margin-top: 2vw;
    padding-bottom: 3vw;
    position: relative;
}