.right_settings_image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 1.2vw;
  padding-bottom: 0.8vw;
}
.right_settings_image_title {
  color: #ffffff;
  background: #ab84fd;
  font-size: 1.5vw;
  width: 80%;
  padding: 0.6vw 0vw;
  border-radius: 0 0 1.25vw 1.25vw;
  text-align: center;
  letter-spacing: 0.03vw;
  margin-bottom: 1.5vw;
}
.dragNdropImg {
  width: 3.7vw;
  margin-bottom: 0.5vw;
}
.right_settings_image_dragNdrop {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.right_settings_image_dragImg {
  font-weight: 900;
  font-size: 1vw;
  margin-bottom: 1vw;
}
.right_settings_image_or {
  color: rgba(27, 27, 27, 0.48);
  margin-bottom: 0.5vw;
}
.right_settings_image_load_button {
  width: 80%;
  border-radius: 0.3vw;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border: none;
  color: #ffffff;
  font-size: 1vw;
  letter-spacing: 0.025vw;
  padding: 0.7vw;
  margin-bottom: 1vw;
  cursor: pointer;
  transition: 0.5s;
}
.right_settings_image_load_button:hover {
  transform: scale(1.05);
}
