.right_settings_title_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 1.2vw;
    width: 100%;
    padding: 0 1vw 0.9vw 1vw;
}
.right_settings_title_title{
    color: #ffffff;
    background: #AB84FD;
    font-size: 1.5vw;
    width: 80%;
    padding: 0.6vw 0vw;
    border-radius: 0 0 1.25vw 1.25vw;
    text-align: center;
    letter-spacing: 0.03vw;
    margin-bottom: 1vw;
}
.right_settings_name_textarea{
    border-radius: 0.25vw;
    outline: none;
    overflow: hidden;
    resize: none;
    border: 0.15vw solid #AB84FD;
    width: 80%;
    padding: 0.5vw 1vw 0vw 1vw;
    font-size: 0.85vw;
    text-align: center;
}