.row_coins_nospace{
    display: flex;
    width: 68%;
    align-self: flex-start;
    justify-content: space-between;
    padding: 0 0.7vw;
    margin-bottom: 0.7vw;
}
.birges_title{
    align-self: flex-start;
}
.coinsTitleImg3{
    width: 8.25vw;
    margin-bottom: 0.5vw;
}