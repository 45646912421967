.navbar-mainpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 21vw;
  height: 100vh;
  background: #fbfbfd;
  border-right: 1px solid #e8eaf1;
  padding-bottom: 3vw;
  padding-top: 1.5vw;
  z-index: 5;
  overflow: hidden;
  transition: 0.5s;
}
.search {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
}
.result__em {
  padding: 1vw;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  border-radius: 1vw;
  font-family: "RoadRadio";
  font-size: 0.8vw;
  font-weight: bold;
  margin-bottom: 1vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.result__em p {
  font-family: "RoadRadio";
}
.results {
  height: 15vw;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 1vw;
}
.results::-webkit-scrollbar {
  width: 0;
}
.result__em div {
  font-size: 0.8vw;
  font-family: "RoadRadio";
  color: #fff;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 7px;
  padding: 0.5vw 1vw;
}
.search h4 {
  margin-bottom: 0.5vw;
  font-weight: bold;
  font-size: 1.2vw;
}
.search input {
  width: 80%;
  padding: 0.5vw 1vw;
  border-radius: 1vw;
  outline: none;
  font-family: "RoadRadio";
  border: 1px solid black;
  margin-bottom: 1vw;
}
.logo-img {
  width: 14vw;
  height: 5.6vw;
  margin-bottom: 4.6vw;
  margin-left: 2.6vw;
}
.out_nav {
  position: absolute;
  bottom: 2vw;
  left: 1vw;
  cursor: pointer;
  transition: 0.5s;
}
.out_nav:hover {
  transform: scale(1.1);
}
.choose-template-img {
  width: 15.2vw;
  border-radius: 1vw;
}
.spisok {
  width: 17.5vw;
  margin-left: 1vw;
  list-style: none;
}
.spisok p {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 1.5vw;
  line-height: 110%;
  color: #1b1b1b;
}
.spisok > li {
  display: flex;
  padding: 1vw;
  border-bottom: 2px solid rgba(35, 1, 106, 0.05);
}

ul img {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 0.7vw;
}
.block {
  display: flex;
  align-items: center;
}
.second_spisok {
  list-style: none;
  margin-left: 1.8vw;
}
.second_spisok > li {
  display: flex;
  align-items: center;
  padding-top: 1vw;
}
.spisok_link {
  text-decoration: none;
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 110%;
  color: #1b1b1b;
}
.spisok > li {
  transition: 0.5s;
}
.spisok > .move:hover {
  transform: translateX(10px);
}
